// Packages

import React from 'react'

// Styled Components

import {
  StyledNewsLearningCard,
  StyledHeadeNewsLearningCard,
  StyledFooterNewsLearingCard,
  StyledIconNewsLearningCard,
  StyledTitleNewsLearningCard,
  StyledTypeNewsLearningCard,
  StyledMarkNewsLearningCard,
  StyledMarkTextNewsLearningCard
} from './styledComponents'

type NewsLearningCardProps = {
  title: string
  typeMedia: string
  image: string
  handleClick: () => void
}

const NewsLearningCard: React.FC<NewsLearningCardProps> = (props) => {
  const { title, typeMedia, image, handleClick } = props
  return (
    <StyledNewsLearningCard
      onClick={handleClick}
      data-testid="news-learning-card"
    >
      <StyledMarkNewsLearningCard>
        <StyledMarkTextNewsLearningCard>Nuevo</StyledMarkTextNewsLearningCard>
      </StyledMarkNewsLearningCard>
      <StyledHeadeNewsLearningCard>
        <StyledIconNewsLearningCard src={image} />
        <StyledTitleNewsLearningCard isTextOverflow={title.length > 24}>
          {title}
        </StyledTitleNewsLearningCard>
      </StyledHeadeNewsLearningCard>
      <StyledFooterNewsLearingCard>
        <StyledTypeNewsLearningCard>
          {typeMedia === 'pdf' ? 'INFOGRAFÍA' : 'VIDEO'}
        </StyledTypeNewsLearningCard>
      </StyledFooterNewsLearingCard>
    </StyledNewsLearningCard>
  )
}

export default NewsLearningCard
