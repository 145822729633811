// Packages

import styled from 'styled-components'

export const StyledCategoriesLearning = styled.div`
  width: 144px;
  height: 203px;
  border-radius: 8px;
  box-shadow: 1px 1px 13px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`

export const StyledImageCategories = styled.img`
  height: 63px;
`

export const StyledTitleCategories = styled.h1`
  font-size: 20px;
  margin: 12px 0 15px;
  text-align: center;
  line-height: normal;
  font-weight: 600;
`

export const StyledInfoMediaContainer = styled.div``

export const StyledTextCategories = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #707070;
  margin: 0;
`
