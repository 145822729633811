// Packages
import React from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

// Styled Components
import {
  StyledLearningContainer,
  StyledCategoriesContainer,
  StyledTitleCategoriesLearning
} from './styledComponents'

// Components
import Loading from '../../components/Shared/Loading'
import CategoriesLearning from '../../components/CategoriesLearnig'
import NewsLearning from '../../components/NewsLearningList'

// Query
import {
  GET_NEWS_LEARNING_CONTENT,
  GET_LEARNING_CATEGORIES
} from '../../graphql/queries/learning'

// Redux
import {
  saveCategoryLearning,
  saveTypeMedia
} from '../../redux/ducks/learning/actionsCreators'

// Utils
import { setParameter } from '../../utils/queryString'
import { customEventGA } from '../../utils/googleAnalitycs'
import { setLocalStorageItem } from '../../utils/storage'

// Config
import config from '../../data/env'

// Lodash
import { isEmpty } from 'lodash'

type learningCategoriesTypes = {
  CategoryID: number
  Description: string
  Videos: number
  Infografias: number
  Url: string
}

const LearningContainer: React.FC = () => {
  const dispatch = useDispatch()

  const { data: getLearningCategories, loading: loadingLearningCategories } =
    useQuery(GET_LEARNING_CATEGORIES, {
      fetchPolicy: 'cache-and-network'
    })

  const { data: getNewsLearning, loading: loadingNewsLearningCategories } =
    useQuery(GET_NEWS_LEARNING_CONTENT, {
      variables: { DaysTo: 15 },
      fetchPolicy: 'cache-and-network'
    })

  const handleClickLearningCategory = (
    categoryID: number,
    numberVideo: number,
    numberPDF: number,
    title: string,
    image: string
  ) => {
    customEventGA({
      eventAction: 'select_category_learning',
      eventCategory: title,
      eventLabel: 'categoryLearning'
    })
    setParameter('categoryID', String(categoryID))
    dispatch(
      saveCategoryLearning({
        categoryID,
        numberVideo,
        numberPDF,
        title,
        image
      })
    )

    if (numberVideo === 0) {
      dispatch(saveTypeMedia('pdf'))
    } else {
      dispatch(saveTypeMedia('video'))
    }
  }

  const handleClickNewsLearning = (
    category: number,
    typeMedia: string,
    name: string
  ) => {
    getLearningCategories?.getLearningMenu.forEach((itemCategory: any) => {
      if (itemCategory.CategoryID === String(category)) {
        dispatch(
          saveCategoryLearning({
            categoryID: category,
            image: `${config.app.url}/${itemCategory.Url}`,
            numberPDF: itemCategory.Infografias ? itemCategory.Infografias : 0,
            numberVideo: itemCategory.Videos ? itemCategory.Videos : 0,
            title: itemCategory.Description
          })
        )
        dispatch(saveTypeMedia(typeMedia))
        setParameter('categoryID', String(category))
        setLocalStorageItem('mediaNewLearningSelect', name)
        setLocalStorageItem('typeNewMediaLearningSelect', typeMedia)
      }
    })
  }

  const body = (
    <StyledCategoriesContainer>
      {getLearningCategories &&
        !loadingLearningCategories &&
        getLearningCategories.getLearningMenu.map(
          (category: learningCategoriesTypes) => (
            <CategoriesLearning
              key={`${category.Description}/${category.CategoryID}`}
              categoryID={category.CategoryID}
              title={category.Description}
              numberPDF={category.Infografias ? category.Infografias : 0}
              numberVideo={category.Videos ? category.Videos : 0}
              image={`${config.app.url}/${category.Url}`}
              handleClick={handleClickLearningCategory}
            />
          )
        )}
    </StyledCategoriesContainer>
  )

  return (
    <>
      {loadingNewsLearningCategories || loadingLearningCategories ? (
        <Loading />
      ) : (
        <StyledLearningContainer>
          {!isEmpty(getNewsLearning.getNewLearningContent) &&
            !loadingNewsLearningCategories && (
              <>
                <StyledTitleCategoriesLearning>
                  Añadidos Recientemente
                </StyledTitleCategoriesLearning>
                <NewsLearning
                  listNewsLearning={getNewsLearning.getNewLearningContent}
                  handleClick={handleClickNewsLearning}
                />
              </>
            )}
          <StyledTitleCategoriesLearning>
            Categorias
          </StyledTitleCategoriesLearning>
          {body}
        </StyledLearningContainer>
      )}
    </>
  )
}

export default LearningContainer
