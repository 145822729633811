import React from 'react'
import LearningContainer from '../../containers/LearningContainer'
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../components/SEO'
import LayoutMain from '../../layout'
import { getParameter } from '../../utils/queryString'
import LearningCategoriesContainer from '../../containers/LearningCategoriesContainer'

const LearningPage: React.FC = () => {
  const params = getParameter('categoryID')
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Capacitaciones" />
      <WithPrivateRoute
        component={params ? LearningCategoriesContainer : LearningContainer}
      />
    </LayoutMain>
  )
}

export default LearningPage
