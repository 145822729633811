// Packages

import styled from 'styled-components'

export const StyledNewsLearningContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 18px;
  &::-webkit-scrollbar {
    display: none;
  }
`
