// Packages

import React from 'react'

// Styled Components

import { StyledNewsLearningContainer } from './styledComponent'

// Components

import NewsLearningCard from '../NewsLearningCard'

// Utils
import config from '../../data/env'

type listNewsLearningOProps = {
  LastUpdatedAt: string
  Name: string
  CategoryID: number
  Duration: string
  CategoryUrl: string
}

type NewsLearningProps = {
  listNewsLearning: Array<listNewsLearningOProps>
  handleClick: (category: number, typeMedia: string, name: string) => void
}

const NewsLearning: React.FC<NewsLearningProps> = (props) => {
  const { listNewsLearning, handleClick } = props

  return (
    <StyledNewsLearningContainer>
      {listNewsLearning.map((newsLearningItem, idx) => {
        const { CategoryID, CategoryUrl, Name, Duration } = newsLearningItem
        const newsTypeMedia = Duration === 'N/A' ? 'pdf' : 'video'

        return (
          <NewsLearningCard
            key={`${Name}-${CategoryID}-${idx}`}
            title={Name}
            typeMedia={newsTypeMedia}
            image={`${config.app.url}/${CategoryUrl}`}
            handleClick={() => handleClick(CategoryID, newsTypeMedia, Name)}
          />
        )
      })}
    </StyledNewsLearningContainer>
  )
}

export default NewsLearning
