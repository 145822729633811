// Actions
import { SAVE_TYPE_MEDIA, SAVE_CATEGORY_LEARNING } from '../actions'

// Types
import { PayloadCategoryLearningTypes } from '../types'

// Action Creators
export const saveTypeMedia = (payload: string) => ({
  type: SAVE_TYPE_MEDIA,
  payload
})

export const saveCategoryLearning = (
  payload: PayloadCategoryLearningTypes
) => ({
  type: SAVE_CATEGORY_LEARNING,
  payload
})
