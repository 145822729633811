// Packages
import styled from 'styled-components'

export const StyledContainerCategories = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
`
export const StyledLearningContainer = styled.div`
  margin: 2%;
`

export const StyledCategoriesContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 25px;
`

export const StyledTitleCategoriesLearning = styled.h1`
  font-size: 18px;
  margin-left: 23px;
  font-weight: 600;
`
