// Packages

import React from 'react'

// Styled Components

import {
  StyledCategoriesLearning,
  StyledImageCategories,
  StyledTitleCategories,
  StyledInfoMediaContainer,
  StyledTextCategories
} from './styledComponents'

type CategoriesLearningProps = {
  categoryID: number
  title: string
  numberVideo: number
  numberPDF: number
  image: string
  handleClick: (
    id: number,
    video: number,
    pdf: number,
    title: string,
    image: string
  ) => void
}

const CategoriesLearning: React.FC<CategoriesLearningProps> = (props) => {
  const { title, numberVideo, numberPDF, image, handleClick, categoryID } =
    props

  return (
    <StyledCategoriesLearning
      onClick={() =>
        handleClick(categoryID, numberVideo, numberPDF, title, image)
      }
    >
      <StyledImageCategories src={image} />
      <StyledTitleCategories>{title}</StyledTitleCategories>
      <StyledInfoMediaContainer>
        <StyledTextCategories>
          {`${numberVideo} ${numberVideo > 1 ? 'Videos' : 'Video'}`}
        </StyledTextCategories>
        <StyledTextCategories>
          {`${numberPDF} ${numberPDF > 1 ? 'Infografías' : 'Infografía'}`}
        </StyledTextCategories>
      </StyledInfoMediaContainer>
    </StyledCategoriesLearning>
  )
}

export default CategoriesLearning
