// Packages

import styled from 'styled-components'

type StyledTitleNewsLearningTypes = {
  isTextOverflow: boolean
}

export const StyledNewsLearningCard = styled.div`
  width: auto;
  height: 110px;
  border-radius: 8px;
  box-shadow: 1px 1px 13px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`

export const StyledHeadeNewsLearningCard = styled.div`
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -20px;
`

export const StyledFooterNewsLearingCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  top: -20px;
`

export const StyledIconNewsLearningCard = styled.img`
  width: 50px;
  height: 50px;
  margin: 0 10px 0 17px;
`

export const StyledTitleNewsLearningCard = styled.p<StyledTitleNewsLearningTypes>`
  font-size: ${({ isTextOverflow }) => (isTextOverflow ? '18px' : '20px')};
  font-weight: 600;
  margin: 0;
  height: 63px;
  line-height: ${({ isTextOverflow }) => isTextOverflow && '22px'};
  display: flex;
  align-items: center;
  justify-content: start;
  width: 150px;
`

export const StyledTypeNewsLearningCard = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  margin-right: 13px;
`

export const StyledMarkNewsLearningCard = styled.div`
  width: 0;
  height: 0;
  border-right: 21px solid transparent;
  border-top: 21px solid #f0ce14;
  border-left: 21px solid #f0ce14;
  border-bottom: 21px solid transparent;
  position: relative;
  border-top-left-radius: 10px;
`

export const StyledMarkTextNewsLearningCard = styled.p`
  font-size: 9px;
  font-weight: 600;
  position: relative;
  top: -1px;
  left: -16px;
  transform: rotate(315deg);
`
